import React, { useState } from 'react';
import ReactPageScroller from 'react-page-scroller';
import { SEO } from '@components/SEO';
import { PageBlock } from '@components/PageBlock';
import { GetStarted } from '@components/GetStarted';
import { PageContainer } from '@components/PageContainer';

const Cloud = () => {
  const [page, setPage] = useState(0);

  const handlePageChange = i => {
    setPage(i);
  };

  return (
    <PageContainer>
      <SEO title="Cloud Partnerships" />
      <div className="hidden lg:block">
        <ReactPageScroller
          blockScrollDown={page >= 1}
          renderAllPagesOnFirstRender={false}
          animationTimer={0}
          pageOnChange={handlePageChange}
        >
          <PageBlock
            header={false}
            title="Cloud Partnerships"
            titleContent={
              <span>
                Cloud Technologies are holding positions in most up-to-date projects. We go forward with{' '}
                <span className="text-orange">AWS, Microsoft</span> and other Cloud tech leaders to make sure we stay on
                edge.
                <br /> <br />
                Certificates we have and partnerships we are involved with, get those solutions going to move your
                business forward.
              </span>
            }
            image={require('@images/comp.png')}
            content={
              <div className="flex flex-col justify-between h-full">
                <div>
                  <h3 className="H3 text-orange mb-2">Our Partners</h3>
                  <div>
                    Working with certified cloud professionals, our clients get reliable cloud architecture matching
                    project needs as opposed to generic settings that pull unnecessary elements for extra budgets.
                    <br /> <br />
                    Cloud services offer tremendous opportunities when it comes to adding value to the product while
                    cutting down the cost at the same time. It is only possible with solid knowledge on how to make it
                    work efficiently. Our specialists constantly learn so you don’t have to worry about it.
                  </div>
                </div>
                <div>
                  <span>Scroll down to see positions</span>
                </div>
              </div>
            }
          />
          <GetStarted />
        </ReactPageScroller>
      </div>
      <div className="lg:hidden">
        <PageBlock
          title="Cloud Partnerships"
          titleContent={
            <span>
              Cloud Technologies are holding positions in most up-to-date projects. We go forward with{' '}
              <span className="text-orange">AWS, Microsoft</span> and other Cloud tech leaders to make sure we stay on
              edge.
              <br /> <br />
              Certificates we have and partnerships we are involved with, get those solutions going to move your
              business forward.
            </span>
          }
          image={require('@images/comp.png')}
          content={
            <div>
              <h3 className="H3 text-orange mb-2">Our Partners</h3>
              <div>
                Working with certified cloud professionals, our clients get reliable cloud architecture matching project
                needs as opposed to generic settings that pull unnecessary elements for extra budgets.
                <br /> <br />
                Cloud services offer tremendous opportunities when it comes to adding value to the product while cutting
                down the cost at the same time. It is only possible with solid knowledge on how to make it work
                efficiently. Our specialists constantly learn so you don’t have to worry about it.
              </div>
            </div>
          }
        />
        <GetStarted />
      </div>
    </PageContainer>
  );
};

export default Cloud;
